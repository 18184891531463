body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidenav {
  height: 100%;
  width: 150px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
/*  font-size: 25px;*/
  color: #818181;
  display: block;
}

.sidenav i {
	width:22px;
	text-align:center;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav span {
	padding-left:5px;
}

.sidenav .active {
	background:rgba(255,255,255,0.2);
	color:#ffffff;
}

.mainheader {
	padding:5px;
	margin:0px -10px 0px -10px;
	background:#111;
	color:white;
	font-weight:700;
}

.main {
  margin-left: 150px; /* Same as the width of the sidenav */
/*  font-size: 28px*/; /* Increased text to enable scrolling */
  padding: 0px 10px;
}

@media screen and (max-width: 600px) {
	.sidenav {
		width:50px;
	}
	.main {
		margin-left:50px;
	}

	.sidenav span {
		display:none;
	}
}



/*
@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
*/

/*
.selectablerow {
	cursor:pointer;
}
.selectablerow:hover {
	background:rgba(0,0,0,0.1);
}
*/
