.selectablerow {
	border-bottom:solid 1px #ddd;
	cursor:pointer;
}
.selectablerow:hover {
	background:rgba(0,0,0,0.1);
}

.header {
	font-weight:700;
	border-bottom:solid 1px #000;
}

.table {
//	padding:20px;
	margin-bottom:20px;
//	box-shadow:1px 1px 5px 0px rgba(0,0,0,0.5);
}
